import './Response.css';
import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';


function useQuery(){
    const {search} = useLocation();
    return React.useMemo(()=>new URLSearchParams(search),[search]);
}

function ApprovedUI(props){

    var state;
    if(parseInt(props.state) === 1){
        state = "APPROVED";
    }else if(parseInt(props.state) === 2){
        state = "REJECTED";
    }

    return(
        <div className='ui_sym'>
            <div className="wrapper"> 
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
            </div>
            <div className="prompt">
                Response Recorded
            </div>
            <h5>REIM{props.reqId} {state}</h5>
            <br/>
            <h6>Comments: </h6>
            <p>{props?.comments}</p>
            <button className="btn btn-secondary" onClick={()=>{window.location.replace("/")}}>Return to Dashboard</button>
        </div>
    )
}

function NoReqError(){
    return(
        <div className="noreqerror">
            <svg viewBox="0 0 24 24">
                <path fill="currentColor" d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
            </svg>
            <h3>ERROR: NO REQUISITION SPECIFIED</h3>
            <h5>If you came to this page expecting something different, please speak to the Administrator for assistance.</h5>
            <button className="btn btn-secondary" onClick={()=>{window.location.replace("/")}}>Return to Dashboard</button>
        </div>
    )
}

function ReqDecidedError(props){
    return(
        <div className="noreqerror">
            <svg viewBox="0 0 24 24">
                <path fill="currentColor" d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
            </svg>
            <h3>REQUISITION {"REIM"+props.reqId} ALREADY HAS A DECISION</h3>
            <h5>If you believe this is an error, please contact the finance team immediately.</h5>
            <button className="btn btn-secondary" onClick={()=>{window.location.replace("/")}}>Return to Dashboard</button>
        </div>
    )
}




export default function HandleResponse(){
    const [checkReq, setChecking] = useState(true);
    const [reqState, setState] = useState(0);
    const [comments, setComments] = useState("");

    var query = useQuery();
    var reqId = query.get("reim");

    useEffect(()=>{
        setChecking(true);
        let req = new XMLHttpRequest();
        req.open('POST', "/api/getReqState?reqId="+reqId);
        req.onreadystatechange = () =>{
            if(req.readyState === XMLHttpRequest.DONE){
                if(req.status === 200){
                    setState(parseInt(req.responseText));
                    setChecking(false);
                }
            }
        }
        req.send();
        setChecking(true);
    },[reqId]);

    console.log(reqState);

    if(!query.has("reim")){
        return(
            <div id="response">
                <NoReqError/>
            </div>
        )
    }else{
        
        if(checkReq){
            return(
                <>
                <div class="spinner-border  spinner-border-sm" role="status">
                    <span class="sr-only"/>
                </div>
                </>
            )
        }else{
            if(reqState !== 0){
                return(
                    <div id="response">
                        <ReqDecidedError reqId={reqId}/>
                    </div>
                )
            }else{
                if(query.has("state")){
                    let state = query.get("state");
                    let comments = query.get("comm");
    
                    var xmlReq = new XMLHttpRequest();
                    xmlReq.open("POST", "/api/appResponse?reim="+reqId+"&state="+state+"&comm="+comments, true);
                    xmlReq.send();
                    
                    return(
                        <div id="response">
                            <ApprovedUI reqId={reqId} state={state} comments={comments}/>
                        </div>
                    )
                }else{
                    return(
                        <div id="response">
                            <h5>REIM{reqId} requires your decision:</h5>
                            <textarea onChange={(e)=>{setComments(e.target.value)}} placeholder='comments' rows="5" cols="50"></textarea>
                            <table>
                                <tr>
                                    <td>
                                        <button className='btn btn-success' onClick={()=>window.location.replace("./response?reim="+reqId+"&state=1&comm="+comments)}>APPROVE</button>
                                    </td>
                                    <td/>
                                    <td>
                                        <button className='btn btn-danger' onClick={()=>window.location.replace("./response?reim="+reqId+"&state=2&comm="+comments)}>REJECT</button>
                                    </td>
                                </tr>
                            </table>
                            <br/>
                        </div>
                    )
                }
            }
        }
        
    }
}