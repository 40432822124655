import "./Navbar.css";
import {useState} from 'react';

export default function NavBar(props){
    const [loggingOut, setLoggingOut] = useState(false);
    const [mouseOver, setMouseOver] = useState(false);

    function handleLogout(){
        setLoggingOut(true);

        const Http = new XMLHttpRequest();
        Http.open("POST", "/api/gDeAuth", true);
        Http.send();
        
        Http.onreadystatechange = (e) =>{
            if(Http.readyState === XMLHttpRequest.DONE){
                window.location.replace("/");
            }
        }
    }

    function DashboardBtn(){
        if(props.view > 0){
            return (
                <button className="btn btn-outline-danger" onClick={()=>{props.viewCtrl(0)}}>Dashboard</button>
            )
        }else{
            return ("");
        }
    }

    return(
        <div id="navigation">
            <nav className="navbar navbar-light bg-light">
                <div className="container-fluid">
                    <DashboardBtn/>
                    <div className="navbar-brand">
                        <img src="https://power-unit.org/images/logos/PUYO.png" alt="Power Unit Youth Organization Logo" className="d-inline-block align-text-middle"/>
                        payME
                    </div>
                    <div className="profile" type="button" onMouseOver={()=>setMouseOver(true)} onMouseLeave={()=>setMouseOver(false)} onClick={()=>{handleLogout()}}>
                        <img id="profileImg" src={props.userimg} alt={props.username} style={{filter: mouseOver? "brightness(0.8)":"brightness(1)"}}/>
                        <svg viewBox="0 0 24 24" style={{display: mouseOver&&!loggingOut? "block":"none"}}>
                            <path fill="currentColor" d="M19,3H5C3.89,3 3,3.89 3,5V9H5V5H19V19H5V15H3V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M10.08,15.58L11.5,17L16.5,12L11.5,7L10.08,8.41L12.67,11H3V13H12.67L10.08,15.58Z" />
                        </svg>
                        <div className="spinner-border" role="status" style={{display: loggingOut? "block":"none"}}>
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </nav>
            <div id="userInfo">
                <b>{props.username}</b> (<small>{props.useremail}</small>)
            </div>
        </div>
      )
}