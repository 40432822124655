import './Requests.css';

export default function Requests(){
    return(
        <div id="requests">
            <div className="head">
                <h4>My Requests</h4>
            </div>
            <div className="body">
                ...eventually there'll be a table here
            </div>
        </div>
    )
}