import "./Auth.css";
import React, {useState} from 'react';


export default function Authentication(){
    const [processing, setProcessing] = useState(false);

    function handleAuth(e){
        e.preventDefault();
        setProcessing(true);
        window.location.assign("./api/gAuth?callback=" + window.location.pathname + window.location.search);
    }
    
    function LoginBtnText(){
        if(processing){
            return(
                <>
                <div class="spinner-border  spinner-border-sm" role="status">
                    <span class="sr-only"/>
                </div>
                </>
            )
        }else{
            return(
                <>
                    Login
                    <svg viewBox="0 0 24 24">
                        <path fill="currentColor" d="M10,17L6,13L7.41,11.59L10,14.17L16.59,7.58L18,9M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1Z" />
                    </svg>
                </>
            )
        }
    }

    return(
        <div id="auth_page" className="container-fluid">
            <div id="credentials">
                <div id="cred_logo">
                    <img src="https://power-unit.org/images/logos/PUYO.png" alt="Power Unit Youth Organization Logo"/>
                </div>
                <h3>Authentication Required</h3>
                <div id="cred_disclaimer">
                    <hr/>
                    <b>Notice:</b>
                    <p>
                        This is shall serve as official notice of resources beyond this point are property of Power Unit Youth Organization ("PUYO") and are intended to be used by PUYO and/or parties authorized on their behalf.<br/>
                        <br/>
                        Unlawful use of these resources may be subject to prosecution as deemed necessary in respective juristiction(s).<br/>
                    </p>
                </div>
                
                <p>
                    By clicking "Login" you agree to the statement.
                </p>
                <button className="btn btn-primary" onClick={(e)=>handleAuth(e)}><LoginBtnText/></button>
            </div>
        </div>
    )





}
