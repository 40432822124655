import {useState} from 'react';

import './Landing.css';

import NavBar from "../../components/Navbar/Navbar";
import DashboardMenu from '../../components/Dashboard/Dashboard';
import Instructions from '../../components/Instructions/Instructions';
import ReimbForm from '../../components/Form/ReimbForm';
import Requests from '../../components/Requests/Requests';


export default function Landing(props){
    const [view, setView] = useState(0);
    
    function ViewHandler(){
        switch(view){
            case 0:
                return <DashboardMenu viewCtrl={setView}/>
            case 1:
                return <Instructions/>
            case 2:
                return <ReimbForm userInfo={props.user} viewCtrl={setView}/>
            case 3:
                return <Requests/>
            default: 
                return <DashboardMenu viewCtrl={setView}/>
        }
    }

    return(
        <div id="dashboard">
            <NavBar username={props.user.names[0].displayName} userimg={props.user.photos[0].url} useremail={props.user.emailAddresses[0].value} viewCtrl={setView} view={view}/>
            <div className="container">
                <ViewHandler/>
            </div>
        </div>
    )
}