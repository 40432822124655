import './App.css';

import {useState, useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';

import Auth from './Auth/Auth';
import Landing from './Landing/Landing';
import HandleResponse from './Response/Response';

function App() {
  const [user, setUser] = useState();
  const [isLoaded, setLoaded] = useState(false);

  /**
   * reterive user information from google Peoples API
   * 
   * doubles as authentication/token verifier
   * if no user is assigned, then user is unauthenticated and should prompt for login
   */
  useEffect(()=>{ //useEffect resolves issue where the API call is being executed a bunch of times at render/state change
    const Http = new XMLHttpRequest();
    Http.open("POST", "/api/gPeople", true);
    Http.onreadystatechange = (e) =>{
      if(Http.readyState === XMLHttpRequest.DONE){
        setLoaded(true);
        //if user succesfully authenticates, api returns 200 and user information in response body
        if(Http.status === 200){
          setUser(JSON.parse(Http.responseText));
        }
      }
    }
    Http.send();
  },[]);
  
  // function HandleNotFound(){
  //   window.location.replace("/");
  // }

  if(isLoaded){ //if google api loading, return spinner (usually doesn't take long)
    if(user !== undefined){ //here's the authentication "lockout", if user is undefined, then we know user is unauthenticated
      return(
        <div className="App"> 
          <Routes>
            <Route path="/response" element={<HandleResponse use={user}/>}/>
            <Route exact path="/" element={<Landing user={user}/>}/>
          </Routes>
        </div>
      )
    }else{
      return(
        <div className="App"> 
          <Auth/>
        </div>
      )
    }
  }else{
    return(
      <div className="App">
        <div class="spinner-border" role="status">
          <span class="sr-only"/>
        </div>
      </div>
    )
  }


}

export default App;
