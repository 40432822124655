import './Instructions.css';

export default function Instructions(){
    return(
        <div id="instructions" className="container">
            <h4>How to use the reimbursement form?</h4>
            <div className="contents">
                Some instructions here
            </div>
        </div>
    )
}