import './Dashboard.css';

function Card(prop){
    //title, body, imgSrc, link
    return(
        <>
        <div className="card" onClick={()=>{prop?.onClickFunc(prop?.sendTo)}}>
            <img className="card-img" src={prop?.imgSrc} alt={prop?.title}/>
            <div className="card-img-overlay">
                <h6 className="card-title">{prop?.title}</h6>
                <div className="card-text">{prop?.body}</div>
            </div>
        </div>
        </>
    )
}

export default function DashboardMenu(prop){
    return(
        <div id="dashboard_btn" className="container-fluid">
            <div className="row">
                <div className="col-sm">
                    <Card title="How-To Use" imgSrc="https://cdn.pixabay.com/photo/2014/07/06/13/55/calculator-385506__340.jpg" onClickFunc={prop.viewCtrl} sendTo={1}/>
                </div>
                <div className="col-sm">
                    <Card title="Create a New Request" imgSrc="https://cdn.pixabay.com/photo/2014/07/06/13/55/calculator-385506__340.jpg" onClickFunc={prop.viewCtrl} sendTo={2}/>
                </div>
                <div className="col-sm">
                    {/*<Card title="My Requests" imgSrc="https://cdn.pixabay.com/photo/2014/07/06/13/55/calculator-385506__340.jpg" onClickFunc={prop.viewCtrl} sendTo={3} disabled/>*/}
                </div>
            </div>
        </div>
    )
}