import React, {useEffect, useState} from 'react';
import './ReimbForm.css';


/*
    Default method for the reimbursement form
*/
export default function ReimbForm(props){
    /*  user info via prop  */
    let user = props.userInfo;

    /*  dynamic records table   */
    const [table, setTable] = useState([{id: 1, type: -1, acct: -1, dop: null, ven: null, desc: null, sub: 0.00, tax: 0.00, tot: 0.00}]);
    /*  dynamic records     */
    const [subName, setName] = useState(user.names[0].givenName + " " + user.names[0].familyName);
    const [subEmail, setEmail] = useState(user.emailAddresses[0].value);
    const [approver, setApproval] = useState("");
    const [payee, setPayee] = useState("");
    const [project, setProject] = useState("");
    const today = new Date();
    const todayString = today.getFullYear().toString() + "-" + (today.getMonth()+1).toString().padStart(2,"0") + "-" + today.getDate().toString().padStart(2,"0");
    var files = [];

    /*  dynamic records totals  */
    const [subSubtotal, setSubtotal] = useState(0.00);
    const [subTax, setTax] = useState(0.00);
    const [subTotal, setTotal] = useState(0.00);

    /*  dynamic form selectors  */
    const [projectsList, setProjects] = useState([]);
    const [types, setType] = useState([]);
    const [typeLoading, setTypeLoading] = useState(false);
    const [accounts, setAccounts] = useState([[]]);
    const [acctLoading, setAcctLoading] = useState(false);

    /*  states for render   */
    const [errorMsg, setErrorMsg] = useState("");
    const [processing, setProcessing] = useState(false);
    const [procMsg, setProcMsg] = useState("Processing your request...");
    const [complete, setComplete] = useState(false);
    const [reqId, setReqId] = useState(0);
    const [filesUploaded, setFilesUploaded] = useState(0);


/*  -----   Table Operators    ----- */

    /**
     * adds a new row to record table
     */
    function newItemRow(){
        let tmpArr = accounts;
        tmpArr.push([]);
        setAccounts(tmpArr);
        var num = table[table.length-1].id + 1;
        var row = {id: num, type: -1, acct: -1, dop: null, ven: null, desc: null, sub: 0.00, tax: 0.00, tot: 0.00};
        setTable([...table, row]);
    }

    /**
     * adds a new row to record table
     */
    function resetTable(){
        setAccounts([[]]);
        var row = {id: 1, type: -1, acct: -1, dop: null, ven: null, desc: null, sub: 0.00, tax: 0.00, tot: 0.00};
        setTable([row]);
    }

    /**
     * change handler for table field modifications (per row)
     * @param {Event} e event
     * @param {int} type expense type identifier
     * @param {int} id reqId
     * @returns 
     */
    function handleChangeExpenses(e, type, id){
        switch(type){
            case 0:
                table[id].type = e.target.value;
            break;
            case 1:
                table[id].acct = e.target.value;
            break;
            case 2:
                table[id].dop = e.target.value;
            break;
            case 3:
                table[id].ven = e.target.value;
            break;
            case 4:
                table[id].desc = e.target.value;
            break;
            case 5:
                table[id].sub = e.target.value;
            break;
            case 6:
                table[id].tax = e.target.value;
            break;
            case 7:
                table[id].tot = e.target.value;
            break;
            default:
                return 500;
        }
        updateTotals(); //updates record tables (at top)
        setTable([...table]); //updates the table view with current information
    }

    /**
     * updates the totals
     */
    function updateTotals(){
        var total = 0;
        table.forEach((item)=>{
            total += parseFloat(item.sub);
        })
        setSubtotal(total);
        total = 0;

        table.forEach((item)=>{
            total += parseFloat(item.tax);
        })
        setTax(total);
        total = 0;

        table.forEach((item)=>{
            total += parseFloat(item.tot);
        })
        setTotal(total);
    }

    /**
     * append files list
     * @param {Event} event
     */
    function handleFileUpload(event){
        var fileList = event.target.files;
        var fileArr = [];
        Array.from(fileList).forEach(file=>fileArr.push(file));
        files=fileArr;
    }


/*  ----- API Runners  -----    */

    /**
     * reterives the project list from api
     */
    function getProjects(){
        var xmlReq = new XMLHttpRequest();
        xmlReq.open("POST", "/api/getProject", true);
        xmlReq.onreadystatechange = (e)=>{
            if((xmlReq.readyState === XMLHttpRequest.DONE) && (xmlReq.status === 200)){
                setProjects(JSON.parse(xmlReq.responseText));
            }
        }
        xmlReq.send();
    }

    /**
     * reterives the expense type list from api based on project selection
     * @param {string} projectName string representation of project name
     */
    function getExpenseType(projectName){
        var xmlReq = new XMLHttpRequest();
        xmlReq.open("POST", "/api/getType?proj="+projectName, true);
        xmlReq.onreadystatechange = (e)=>{
            if((xmlReq.readyState === XMLHttpRequest.DONE) && (xmlReq.status === 200)){
                setType(JSON.parse(xmlReq.responseText));
                setTypeLoading(false);
            }
        }
        xmlReq.send();
        setTypeLoading(true);
    }

    /**
     * reterives the expense account list from api based on project and team selection
     * @param {string} projectName  string representation of project name
     * @param {string} teamName string representation of team name
     * @param {int} rowId integer representation of table row
     */
    function getAccount(projectName, teamName, rowId){
        var xmlReq = new XMLHttpRequest();
        xmlReq.open("POST", "/api/getAccounts?proj="+projectName+"&team="+teamName, true);
        xmlReq.onreadystatechange = (e)=>{
            if((xmlReq.readyState === XMLHttpRequest.DONE) && (xmlReq.status === 200)){
                var tmpArr = accounts;
                if(rowId > tmpArr.length){
                    tmpArr.push(JSON.parse(xmlReq.responseText));
                }else{
                    tmpArr[rowId] = JSON.parse(xmlReq.responseText);
                }
                setAccounts(tmpArr);
                setTable([...table]);
                setAcctLoading(false);
            }
        }
        xmlReq.send();
        setAcctLoading(true);
    }

    /**
     * initiailze request requsition with API
     * @returns requsition(reqId) and folder(folderId) ID in JSON
     */
    async function initReq(){
        return new Promise((resolve)=> {
            let req = new XMLHttpRequest();
            req.open('POST', '/api/initNewReq');
            req.onload = () =>{
                if(req.readyState === XMLHttpRequest.DONE){
                    if(req.status === 200){
                        resolve(JSON.parse(req.responseText));
                    }
                }
            }
            req.send();
            console.log('initReq_> initializing requsition');
        })
    }

    /**
     * send form to API for processing
     * @param {JSON} jsonString stringified json
     * @param {int} reqId requisition Id number
     * @param {string} formId folder Id number
     */
    function processForm(jsonString, reqId, folderId){
        let req = new XMLHttpRequest();
        req.open('POST', '/api/processForm?reqId=' + reqId + '&folderId=' + folderId);
        req.send(jsonString);
        console.log('processForm_> processing form');
    }

    /**
     * sends each file to api for upload
     * @param {int} reqId requisition Id number
     * @param {Array<File>} fileArray array of File objects
     * @param {string} fileFolder string representation of Google Drive folder id
     */
    async function uploadFiles(reqId, fileArray, fileFolder){
        //for eachfile in fileArray, api is called to handle individual upload
        fileArray.forEach((file, index) =>{
            setProcMsg("Uploading documents... (" + (parseInt(index) + 1) + " of " + fileArray.length + ")");   //process indicator
            var xmlReq = new XMLHttpRequest();
            xmlReq.open("POST", "/api/uploadFiles?reqId=" + reqId + "&type=" + file.type + "&fileName=" + file.name + "&folderId="+ fileFolder);
            xmlReq.onreadystatechange = (e)=>{
                if(xmlReq.readyState === XMLHttpRequest.DONE){
                    if(xmlReq.status === 200){
                        setFilesUploaded(filesUploaded + 1);
                    }
                }
            }
            xmlReq.send(file);
            console.log('uploadFiles> uploading file');
        });
    }

    /* ----- form submit handling ----- */

    function validateTable(){
        return table.every((row, idx) =>{
            if(row.type === -1){
                setErrorMsg("Expense Type at row " + parseInt(idx+1) + " is required.");
                return false;
            }else if(row.acct === -1){
                setErrorMsg("Expense Account at row " + parseInt(idx+1) + " is required");
                return false;
            }else if(row.dop === null){
                setErrorMsg("Expense Date of Purchase at row " + parseInt(idx+1) + " is required");
                return false;
            }else if(row.ven === null){
                setErrorMsg("Vendor at row " + parseInt(idx+1) + " is required");
                return false;
            }else if(row.sub === 0){
                setErrorMsg("Expense Subtotal at row " + parseInt(idx+1) + " cannot be $0.00");
                return false;
            }else if(row.tot === 0){
                setErrorMsg("Expense Total at row " + parseInt(idx+1) + " cannot be $0.00");
                return false;
            }else{
                setErrorMsg("");
                return true;
            }
        });
    }


    function validateForm(){
        if(subEmail === approver){
            setErrorMsg("Submittee cannot approve own reimbursement");
            return false;
        }else if(!subEmail.includes("@power-unit.org")){
            setErrorMsg("Submittee must be a valid PUYO member (puyo email only)");
            return false;
        }else if(!approver.includes("@power-unit.org")){
            setErrorMsg("Approver must be a valid PUYO member (puyo email only)");
            return false;
        }else{
            setErrorMsg("");
            return true;
        }
    }


    /*  main submit handler     */
    function handleSubmit(e){

        e.preventDefault();     //prevent form default POST action

        if(validateTable()&&validateForm()){
            console.log('processing');
            setProcessing(true);    //toggle show user prompt with spinny

            /* compile response */
            setProcMsg("Compiling your requisition...");    //set user prompt
            let jsonObject = {  //json object representation of form sent to processForm api
                "subName":subName,
                "subEmail":subEmail,
                "approver":approver,
                "payee":payee,
                "project":project,
                "files":files.length,
                "table":table
            };
    
            /* send response */
            setProcMsg("Creating new requisition...");
            initReq()  //initialize req
            .then((reqInfo)=>{
                setReqId(reqInfo.reqId); //set reqId for ui
                setProcMsg("Sending requisition...");
                processForm(JSON.stringify(jsonObject), reqInfo.reqId, reqInfo.folderId);   //send form to API
                return reqInfo;
            })
            .then((reqInfo)=>{
                setProcMsg("Uploading Files");
                if(files.length > 0){   //if there are files then...
                    uploadFiles(reqInfo.reqId, files, reqInfo.folderId); //upload files
                }
                return reqInfo;
            })
            .then(()=>{
                setComplete(true);
                setProcessing(false);
            });
        }        
    }

    

    /* reterive project list from api at load, unless its already loaded */
    useEffect(()=>{
        getProjects();
    },[]);
    
    /* ----- ui elements ----- */

    /* loading spinner */
    if(processing && !complete){
        return(
            <div id="reimbForm_prompt" className='row'>
                <div className='col-2'>
                    <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
                <div className='col-8'>
                    {procMsg}
                </div>
            </div>
        )
    /* confirmation dialog */
    }else if(!processing && complete){
        return(
            <div id="reimbForm_prompt" className='row'>
                <div className='col-2'>
                    <svg viewBox="0 0 24 24">
                        <path fill="currentColor" d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
                    </svg>
                </div>
                <div className='col-8'>
                    <b>Request Processed!</b><br/>
                    Your reference number is: <b>REIM{reqId}</b>
                    <br/><br/>
                    <i>Please retain this for your records.</i>
                    <br/>
                    <button className="btn btn-outline-secondary" onClick={()=>{props.viewCtrl(3)}}>View My Requets</button>
                </div>
            </div>
        )
    /* reimbursement form */    
    }else{
        return(
            <div id="reimbForm_area">
                <div className="alert alert-danger" role="alert">
                    Attention - No contents are saved until form submission, always keep a backup copy of all files for safety.
                </div>
                <form onSubmit={(e)=>handleSubmit(e)} name="reimbForm">
                    {/* submittor information */}
                    <div className="row">
                        <div className="form-group">
                            <label htmlFor="fullName">Full Name</label>
                            <input type="text" className="form-control" id="fullName" placeholder="What's your First and Last name?" defaultValue={subName} onChange={(e)=>setName(e.target.value)} required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="subEmail">PUYO Email</label>
                            <input type="email" className="form-control" id="subEmail" placeholder="What's your @power-unit.org email?" defaultValue={user.emailAddresses[0].value} onChange={(e)=>setEmail(e.target.value)} required/>
                        </div>
                    </div>
                    {/* cheque information */}
                    <div className="row">
                        <div className="form-group">
                            <label htmlFor="appEmail">Approver's Email</label>
                            <input type="email" className="form-control" id="appEmail" placeholder="Who will be approving this?" aria-describedby="appEmailHelp" onChange={(e)=>setApproval(e.target.value)} required/>
                            <small id="appEmailHelp">This should NOT your email address.</small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="payee">Payee</label>
                            <input type="text" className="form-control" id="payee" placeholder="Who should the cheque be made out to?" onChange={(e)=>setPayee(e.target.value)}  required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="project">Project <sub>required for expense type and account to be available</sub></label>
                            <select className="form-control" id="project" defaultValue={"-1"} onChange={(e)=>{setProject(e.target.value); resetTable(); getExpenseType(e.target.value)}} required>
                                <option value="-1" disabled>
                                    {(project !== undefined)? "(select project)":"loading..."}    
                                </option>
                                {projectsList.map((type, key)=>(
                                        <>
                                        <option key={key} value={type.trim()}>{type.trim()}</option>
                                        </>
                                    ))}
                            </select>
                        </div>
                    </div>
                    {/* itemized espense list */}
                    <div className="row table-responsive">
                        <table id="exptbl" className="table table-hover">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Team/Expense Type</th>
                                    <th scope="col">Account</th>
                                    <th scope="col">Date of Purchase</th>
                                    <th scope="col">Vendor</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Subtotal ($)</th>
                                    <th scope="col">GST/HST ($)</th>
                                    <th scope="col">Total ($)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {table.map((row, rowKey)=>(
                                    <tr>
                                        <td key={rowKey + "id"}>{row.id}</td>
                                        <td key={rowKey + "type"}>
                                            <select className="form-control" value={table[rowKey].type} disabled={typeLoading} onChange={(e)=>{handleChangeExpenses(e, 0, rowKey); getAccount(project, e.target.value, rowKey)}} required>
                                                <option value={-1} selected disabled></option>
                                                {types.map((type, typeKey)=>(
                                                    <>
                                                    <option key={typeKey} value={type.trim()}>{type.trim()}</option>
                                                    </>
                                                ))}
                                            </select> 
                                        </td>
                                        <td key={rowKey + "acct"}>
                                            <select className="form-control" value={table[rowKey].acct} disabled={acctLoading} onChange={(e)=>handleChangeExpenses(e, 1, rowKey)} required>
                                                <option value={-1} selected disabled></option>
                                                {accounts[rowKey].map((acct, acctKey)=>(
                                                    <>
                                                    <option key={acctKey} value={acct.trim()}>{acct.trim()}</option>
                                                    </>
                                                ))}
                                            </select>
                                        </td>
                                        <td key={rowKey + "dop"}>
                                            <input type="date" className="form-control" id="dop" max={todayString} value={table[rowKey].dop} onChange={(e)=>handleChangeExpenses(e, 2, rowKey)} required/>
                                        </td>
                                        <td key={rowKey + "ven"}>
                                            <input type="text" className="form-control" id="ven" value={table[rowKey].ven} onChange={(e)=>handleChangeExpenses(e, 3, rowKey)} required/>
                                        </td>
                                        <td key={rowKey + "desc"}>
                                            <input type="text" className="form-control" id="desc" value={table[rowKey].desc} onChange={(e)=>handleChangeExpenses(e, 4, rowKey)} required/>
                                        </td>
                                        <td key={rowKey + "sub"}>
                                            <input type="number" min="0.00" step="0.01" className="form-control" id="sub" placeholder="0.00" onChange={(e)=>handleChangeExpenses(e, 5, rowKey)} required/>
                                        </td>
                                        <td key={rowKey + "tax"}>
                                            <input type="number" min="0.00" step="0.01" className="form-control" id="tax" placeholder="0.00" onChange={(e)=>handleChangeExpenses(e, 6, rowKey)}/>
                                        </td>
                                        <td key={rowKey + "tot"}>
                                            <input type="number" min="0.00" step="0.01" className="form-control" id="total" placeholder="0.00" onChange={(e)=>handleChangeExpenses(e, 7, rowKey)} required/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>Totals:</td>
                                    <td>
                                        ${Number.parseFloat(subSubtotal).toFixed(2)}
                                    </td>
                                    <td>
                                        ${Number.parseFloat(subTax).toFixed(2)}
                                    </td>
                                    <td>
                                        ${Number.parseFloat(subTotal).toFixed(2)}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                        <div className="col">
                            <br/>
                            <button id="newRowBtn" className="btn btn-primary" onClick={()=>newItemRow()}>
                                <svg viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z" />
                                </svg>
                                Add Entry
                            </button>
                        </div>
                    </div>
                    {/* proof of expense upload */}
                    <div className="row">
                        <label className="form-label" htmlFor="file">Upload supporting documents (e.g. receipts, invoices)</label>
                        <input type="file" className="form-control form-control-lg" id="file"  onChange={(e)=>handleFileUpload(e)} multiple/>
                    </div>
                    {/* terms and conditions */}
                    <div className="row">
                        <h4>Terms and Conditions</h4>
                        <hr/>
                        <div id="tc_body">
                            <p> 
                                I {subName.toUpperCase()} hereby certify that, to the best of my knowledge, the provided information is true and accurate, and that I am in good standing with Power Unit Youth Organization ("PUYO") its projects and affiliates. I have also understood the terms and conditions outlined below to their fullest extent or have sought inquiry in an attempt to understand them.
                            </p>
                            <ul>
                                <li key={"tcpt1"}>
                                    Reimbursement requisitions are only for expenses related to the operations of the organization, its projects, and/or affiliated activities and all expenses must be backed by a valid proof of purchase/expenditure such as receipts or invoices with transactional information clearly displayed (such as the date and time, description, taxes, and totals).
                                </li>
                                <li key={"tcpt2"}>
                                    All reimbursements are subject to review and approval by the approver and/or organization, project, or other appointed accountant/individual prior to release of funds.
                                </li>
                                <li key={"tcpt3"}>
                                    The person who's information is indicated as the submittor ({subName.toUpperCase()}) will be responsible and held accountable for the requisition submitted upto and beyond the period at which the funds are released.
                                </li>
                                <li key={"tcpt4"}>
                                    At any time, the organization and/or delegates may audit the requisition and request more information from the submittor.
                                </li>
                            </ul>
                            <p>
                                Dependent on the time of year and number of requisitions, I understand that I may not receive my funds in a timely manner, and should I require additional information or support regarding my reimbursement, such as requesting requisition to be expedited or mailed, I can contact my Project Accountant at the emails listed below, or the PUYO finance team at <a href="mailto:finance@power-unit.org">finance@power-unit.org</a>, additionally I can seek my direct supervisor for assistance regarding this matter.
                                <br/>
                                <b>PUYO General</b> - <a href="mailto:finance@power-unit.org">finance@power-unit.org</a>
                                <br/>
                                <b>NIU!</b> - <a href="mailto:niu-finance@power-unit.org">niu-finance@power-unit.org</a>
                                <br/>
                                <b>E3</b> - <a href="mailto:e3-finance@power-unit.org">e3-finance@power-unit.org</a>
                                <br/>
                                <b>LUCC</b> - <a href="mailto:lucc-finance@power-unit.org">lucc-finance@power-unit.org</a>
                                <br/>
                                <b>ATO</b> - <a href="mailto:ato-finance@power-unit.org">ato-finance@power-unit.org</a>
                                <br/>
                            </p>
                        </div>
                        <hr/>
                        <p>By clicking Submit, you agree to the above terms and conditions.</p>
                        <div className="col">
                            <button id="submitBtn" className="btn btn-success" type="submit">
                            <svg viewBox="0 0 24 24">
                                <path fill="currentColor" d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
                            </svg>
                            Submit
                            </button>
                        </div>
                        <div id="errorPrompt">
                            {errorMsg.toUpperCase()}
                        </div>
                    </div>
                </form>
            </div>
        )

    }
}